<template>
  <el-dialog
    title="客户选择"
    width="60%"
    :visible.sync="dialogTableVisible"
    append-to-body
  >
    <div class="searchNav">
      <div class="searchParams">
        <el-input
          class="input"
          v-model="queryParam.Keyword"
          placeholder="请输入编号或名称"
          clearable
          @keydown.enter.native="getDataList"
        ></el-input>
        <el-button class="button" @click="getDataList">查询</el-button>
      </div>
    </div>
    <div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        v-loading="loading"
        style="width: 100%"
        :max-height="450"
        @selection-change="handleSelectionChange"
      >
        <el-table-column v-if="type == 'radio'" prop="cid" label="" width="50">
          <template slot-scope="scope">
            <el-radio v-model="radioSelect" :label="scope.$index">{{
              ""
            }}</el-radio>
          </template>
        </el-table-column>
        <el-table-column v-else type="selection" width="55"> </el-table-column>
        <el-table-column prop="cCusCode" label="客户编号" width="120">
        </el-table-column>
        <el-table-column prop="cCusName" label="客户名称" width="240">
        </el-table-column>
        <el-table-column
          prop="cCusAddress"
          label="地址"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
      </el-table>
      <el-pagination
        layout="total,prev, pager, next,sizes"
        :total="pagination.total"
        :current-page="pagination.current"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.pageSizeOptions"
        style="text-align: right; margin-top: 8px; padding: 0"
        @current-change="currentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { toThousands } from "@/utils/Tools";
export default {
  props: {
    type: { require: false, default: "radio", type: String },
  },
  mounted() {},
  created() {},
  data() {
    return {
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      radioSelect: null,
      sorter: {
        field: "Id",
        order: "desc",
      },
      tableData: [],
      queryParam: { condition: "cInvName", SupplieId: "全部" },
      loading: false,
      toThousands,
      dialogTableVisible: false,
      selectRows: [],
    };
  },
  methods: {
    handleSelectionChange(val) {
      this.selectRows = val;
    },
    // 取消
    cancel() {
      this.dialogTableVisible = false;
    },
    // 确认
    confirm() {
      if (this.type == "radio") {
        if (this.radioSelect === null) {
          this.$message.error("请选择客户");
          return;
        }
        this.$emit("select", this.tableData[this.radioSelect]);
        this.dialogTableVisible = false;
      } else {
        if (this.selectRows.length <= 0) {
          this.$message.error("请选择客户");
          return;
        }
        this.$emit("select", this.selectRows);
        this.dialogTableVisible = false;
      }
    },
    // 开启页面
    openForm(CompanyId) {
      if (this.$refs.multipleTable) {
        this.$refs.multipleTable.clearSelection();
      }

      this.queryParam.SupplieId = CompanyId;
      this.getDataList();
      this.dialogTableVisible = true;
    },
    currentChange(page) {
      this.pagination.current = page;
      this.getDataList();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getDataList();
    },
    getDataList() {
      this.radioSelect = null;
      this.loading = true;
      this.$http
        .post("/BO/CustomerInfo/GetDataList", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || "Id",
          SortType: this.sorter.order,
          Search: this.queryParam,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            this.tableData = resJson.Data;
            if (this.tableData.length > 0) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          }
        });
    },
  },
  computed: {},
  watch: {},
};
</script>

<style lang="less" scoped>
::v-deep .el-input__validateIcon {
  display: none;
}
</style>
