<template>
  <div>
    <el-autocomplete
      style="width: 100%"
      v-model="curValue"
      :fetch-suggestions="CustomerSearchAsync"
      value-key="cCusName"
      placeholder="请输入客户名称进行查询"
      @select="handleCustomerSelect"
      @change="change"
      :trigger-on-focus="false"
      :disabled="disabled"
    >
      <el-button
        style="margin-left: 8%"
        slot="suffix"
        icon="el-icon-search"
        type="primary"
        @click="handleAdd()"
      ></el-button>
    </el-autocomplete>

    <ConsigneeInquiry ref="consigneeInquiry" @select="Inquiry" />
  </div>
</template>

<script>
import ConsigneeInquiry from "@/components/ConsigneeInquiry.vue";
export default {
  components: {
    ConsigneeInquiry,
  },
  props: {
    value: { require: false, default: "", type: String },
    disabled: { require: false, default: false, type: Boolean },
    CompanyId: { require: false, default: "", type: String },
  },
  data() {
    return {
      curValue: "",
    };
  },
  watch: {
    value: {
      handler(n, o) {
        this.curValue = n;
        this.$emit("input", this.curValue);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    change(a) {
      // this.curValue = ''
      console.log(a);
    },
    CustomerSearchAsync(queryString, cb) {
      clearTimeout(this.timeout);
      if (!queryString) {
        return;
      }
      this.timeout = setTimeout(() => {
        this.$http
          .post("/BO/CustomerInfo/GetDataList", {
            PageIndex: 1,
            PageRows: 9999,
            SortField: "Id",
            SortType: "desc",
            Search: {
              condition: "cCusName",
              Keys: queryString,
              SupplieId: this.CompanyId,
            },
          })
          .then((resJson) => {
            if (resJson.Success) {
              cb(resJson.Data);
            }
          });
      }, 1000);
    },

    handleCustomerSelect(item) {
      this.$emit("select", item);
      this.$emit("input", this.curValue);
    },

    // 表格新增
    handleAdd() {
      this.$refs.consigneeInquiry.openForm(this.CompanyId);
    },
    // 客户选择
    Inquiry(rows) {
      this.curValue = rows.cCusName;
      this.$emit("select", rows);
      this.$emit("input", this.curValue);
    },

    openForm(content, mode, Company, TemName) {
      console.log(Company);
      this.init();
      this.Company = Company;
      if (mode) {
        this.title = "编辑回执/服务";
      } else {
        this.title = "新增回执/服务";
        // this.Render(JSON.parse(content))
        console.log(JSON.parse(content));
        var arr = JSON.parse(content).printlist;
        this.jsoncontent = { ...JSON.parse(content) };
        var arr2 = arr.filter((item) => {
          return !item.ispringt;
        });
        // var arr2 = arr.filter((item) => {
        //   return (
        //     item.type === '2' || item.type === '6'
        //     // item.type !== '4' &&
        //     // item.type !== '5' &&
        //     // item.type !== '3' &&
        //     // item.type !== '1' &&
        //     // item.type !== '7'
        //   )
        // })
        arr2.sort((a, b) => a.index - b.index);
        arr2.forEach((item) => {
          if (item.Keys === "Company") {
            this.$set(item, "value", TemName);
            this.$set(this.entity, "Company", Company);
          } else {
            this.$set(item, "value", "");
          }
        });
        console.log(arr2, "123456");
        this.formList = arr2;

        this.tableDataList = arr.filter((item) => {
          return item.type === "4";
        });
        this.tableDataList.forEach((item) => {
          // item.columns = [
          //   { title: '品名', dataIndex: 'cInvName' },
          //   { title: '包装', dataIndex: 'cInvStd' },
          //   { title: '批次号', dataIndex: 'Batch' },
          //   { title: '单净重(KG)', dataIndex: 'Weight' },
          //   { title: '件数', dataIndex: 'Quantity' },
          //   { title: '总重量(KG)', dataIndex: 'Weight1' },
          // ]
          item.datalist = [];
        });
        console.log(arr2);
        // 获取公司发货地址
        this.$http
          .post("/Base_Manage/Base_Company/GetCommadders?compayid=" + Company)
          .then((res) => {
            if (res.Success && res.Data.length > 0) {
              this.$set(this.entity, "gongsiaddress", res.Data[0]);
            }
          });
      }
    },
  },
};
</script>

<style></style>
